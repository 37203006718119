/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

$themecolor: #009efb;
$themecolor-dark: #028ee1;
$topbar: #009efb;
$sidebar:#272c33;
$sidebar-text: #798699;
$sidebar-icons: #798699;
$sidebar-dark: #181c22;
$sidebar-alt: #181c22;
$themecolor-alt: #26c6da;
$topbar-alt: #272c33;
.defaultdark {
/*******************
/*Top bar
*******************/
    .topbar {
        background: $topbar;
        .top-navbar .navbar-header .navbar-brand .dark-logo {
            display: none;
        }
        .top-navbar .navbar-header .navbar-brand .light-logo {
            display: inline-block;
            color: rgba(255, 255, 255, 0.8);
        }
        .navbar-light .navbar-nav .nav-item > {
            a.nav-link {
                color: rgba(255, 255, 255, 0.8)!important;
                &:hover,
                &:focus {
                    color: $white!important;
                }
            }
        }
        .navbar-header {
            background: $topbar-alt;
        }
    }
    .logo-center .topbar {
        .navbar-header {
            background: transparent;
            box-shadow: none;
        }
        .top-navbar .navbar-header .navbar-brand .dark-logo {
            display: none;
        }
        .top-navbar .navbar-header .navbar-brand .light-logo {
            display: inline-block;
            color: rgba(255, 255, 255, 0.8);
        }
    }
    /*******************
/*General Elements
*******************/
    a.link {
        &:hover,
        &:focus {
            color: $themecolor!important;
        }
    }
    .right-sidebar {
        .rpanel-title {
            background: $themecolor;
        }
    }
    .text-themecolor {
        color: $themecolor!important;
    }
    /*******************
/*Buttons
*******************/
    .btn-themecolor,
    .btn-themecolor.disabled {
        background: $themecolor;
        color: $white;
        border: 1px solid $themecolor;
        &:hover {
            background: $themecolor;
            opacity: 0.7;
            border: 1px solid $themecolor;
        }
        &.active,
        &:focus {
            background: $themecolor-dark;
        }
    }
/*******************
/*sidebar navigation
*******************/
    .left-sidebar,
    .card-no-border .left-sidebar,
    .card-no-border .sidebar-nav {
        background: $sidebar;
    }
    .user-profile .profile-text a {
        color: $sidebar-text!important;
    }
    .card-no-border .sidebar-footer {
        background: $sidebar-dark;
    }
    .label-themecolor {
        background: $themecolor;
    }
    .sidebar-nav>ul>li.active>a {
        color: $themecolor;
        border-color: $themecolor;
        i {
            color: $themecolor;
        }
    }
    .sidebar-nav ul li a {
        &.router-link-active,
        &:hover {
            color: $themecolor;
            i {
                color: $themecolor;
            }
        }
    }
    .sidebar-nav ul li.nav-small-cap {
        color: $sidebar-text;
    }
    @media (min-width: 768px) {
    &.mini-sidebar .sidebar-nav #sidebarnav > li > ul {
        background: $sidebar-alt;
    }
    &.mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
        background: $sidebar-alt;
    }
}
}

