/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@import '../variable';
$themecolor: #009efb;
$themecolor-dark: #028ee1;
$topbar: #455a64;
$themecolor-alt: #26c6da;
$topbar-alt: #ffffff;

.default {

/*******************
/*Top bar
*******************/

.topbar {
    background: $topbar;
    .navbar-header{
        background: $topbar-alt;
    }
    
    
    .top-navbar .navbar-header .navbar-brand .light-logo{
        display: none;
        color:rgba(255, 255, 255, 0.8);
    }
    .navbar-light .navbar-nav .nav-item > {
        a.nav-link {
           
            color: $white!important;
            &:hover,
            &:focus {
                 color: rgba(255, 255, 255, 0.8)!important;
            }
        }
    }
}


/*******************
/*General Elements
*******************/

a.link {
    &:hover,
    &:focus {
        color: $themecolor!important;
    }
}

.bg-theme {
    background-color: $themecolor !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: $themecolor;
    border-color: $themecolor;
}

.right-sidebar {
    .rpanel-title {
        background: $themecolor;
    }
}

.stylish-table {
    tbody tr {
        &:hover,
        &.active {
            border-left: 4px solid $themecolor;
        }
    }
}
.text-themecolor{
    color:$themecolor!important;
}
.profile-tab,
.customtab {
    li {
        a.nav-link {
            &.active {
                border-bottom: 2px solid $themecolor;
                color: $themecolor;
            }
            &:hover {
                color: $themecolor;
            }
        }
    }
}


/*******************
/*Buttons
*******************/

.btn-themecolor,
.btn-themecolor.disabled {
    background: $themecolor;
    color: $white;
    border: 1px solid $themecolor;
    &:hover {
        background: $themecolor;
        opacity: 0.7;
        border: 1px solid $themecolor;
    }
    &.active,
    &:focus {
        background: $themecolor-dark;
    }
}


/*******************
/*sidebar navigation
*******************/

.label-themecolor{
    background:$themecolor; 
}

.sidebar-nav>ul>li.active>a {
    color: $themecolor;
    border-color: $themecolor;
    i {
       color: $themecolor; 
    }
}

.sidebar-nav ul li a {
    &.router-link-active,
    &:hover {
      color: $themecolor;
      i {
        color: $themecolor;
      }  
    }
}

}