/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */
 
@import 'variable'; 
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import 'app';
@import 'pages';
@import 'sidebar';
@import '../scss/icons/font-awesome/css/font-awesome.min.css';
@import '../scss/icons/simple-line-icons/css/simple-line-icons.css';
@import '../scss/icons/themify-icons/themify-icons.css'; 
@import "../scss/icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "rtl/rtl";
@import 'widgets';
@import 'grid';
@import 'responsive';  
@import 'spinners.scss'; 
@import 'animate.css';       
@import 'theme-settings.scss';   

// color css

@import 'colors/blue-dark';
@import 'colors/default-dark';
@import 'colors/red-dark';
@import 'colors/green-dark';
@import 'colors/purple-dark';
@import 'colors/megna-dark';
@import 'colors/megna';
@import 'colors/blue';
@import 'colors/red';
@import 'colors/green';
@import 'colors/purple';
@import 'colors/default';

// dark theme
@import 'dark';